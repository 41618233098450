import logo from './logo.svg';
import './App.css';
import { useState } from "react";


function App() {
  const [first, setfirst] = useState(0);
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          first {first} <code>src/App.js</code> and save to reload.
        </p>
   <button onClick={()=>setfirst(prev=>prev+1)}>Inc</button> 
     <button onClick={()=>setfirst(prev=>prev-1)}>Dec</button> 

      </header>
    </div>
  );
}

export default App;
